<template>
  <div class="page-container">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="primary picture-container">
      <v-img :src="homeImg" height="100vh"></v-img>
    </div>
    <div class="form-container">
      <div :style="formContentStyle">
        <LoginForm />
        <div class="switch text-body primary--text">
          <div>{{ forgotPasswordText }}</div>
          <div>
            {{ signupText }} <router-link :to="signupLink.to">{{ signupLink.text }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeImg from '@/assets/home-image@2x.png';
import { LoginForm } from '@/features/Auth';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {
    return {
      homeImg,
      forgotPasswordText: 'Forgot Password',
      signupText: 'Not a member yet?',
      signupLink: {
        text: 'Sign up here',
        to: { name: 'signup' },
      },
      loginText: 'Already a member? Log in here',
    };
  },
  computed: {
    formContentStyle() {
      return {
        width: this.$vuetify.breakpoint.xs ? '280px' : '400px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  height: 100vh;

  & > * {
    flex: 1;
  }
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 55%;
}
.switch {
  text-align: left;
  margin-top: 24px;
  width: 100%;
}
.picture-container {
  position: relative;
  width: 45%;
}
</style>
