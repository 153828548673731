<template>
  <div class="page-container">
    <div class="page-content">
      <div class="title-container">
        <div class="text-title mb-3">{{ title }}</div>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div @click="signOutHandler" v-bind="attrs" v-on="on" class="signout-button">
              <v-icon color="primary mr-2" small>{{ logout.icon }}</v-icon>
              <div class="text-body">{{ logout.text }}</div>
            </div>
          </template>
          <span>{{ logout.tooltip }}</span>
        </v-tooltip>
      </div>
      <div v-if="isLoading" class="page-loader">
        <v-progress-circular :size="80" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else-if="isError">error</div>
      <div v-else>
        <v-stepper v-model="step" class="onboarding-container" vertical>
          <div v-for="({ title, component }, i) in steps" v-bind:key="title">
            <v-stepper-step :complete="step > i + 1" :step="i + 1" class="pa-0 pt-3 pb-3">
              {{ title }}
            </v-stepper-step>
            <v-stepper-content :step="i + 1" class="pa-2 pl-0">
              <div class="pa-2 pb-4 pr-12">
                <component v-if="step === i + 1" :is="component" :user="user" @next="handleNext" />
              </div>
            </v-stepper-content>
          </div>
        </v-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { mapGetters } from 'vuex';
import {
  EmailVerification,
  MobileVerification,
  PersonalInformation,
  BankInformation,
  PendingApproval,
} from '@/features/Onboarding';

export default {
  name: 'Onboarding',
  components: {
    EmailVerification,
    MobileVerification,
    PersonalInformation,
    BankInformation,
    PendingApproval,
  },
  data() {
    return {
      title: 'Welcome to Lyght!',
      logout: {
        text: 'Sign Out',
        tooltip: 'Your progress on the completed steps are saved',
        icon: 'mdi-logout',
      },
      step: 1,
      steps: [
        {
          title: 'Email Verification',
          component: EmailVerification,
        },
        {
          title: 'Mobile Verification',
          component: MobileVerification,
        },
        {
          title: 'Personal Information',
          component: PersonalInformation,
        },
        {
          title: 'Bank Information',
          component: BankInformation,
        },
        {
          title: 'Complete',
          component: PendingApproval,
        },
      ],
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    ...mapGetters('users', {
      user: 'user',
    }),
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    userAuth() {
      return firebase.auth().currentUser;
    },
    isLoading() {
      return !this.userAuth || this.user.loading;
    },
    isError() {
      return !this.userAuth || this.user.error;
    },
  },
  methods: {
    handleNext(step) {
      this.step = step;
    },
    signOutHandler() {
      try {
        this.signOut().then(() => {
          this.$router.replace({ name: 'login' });
        });
      } catch (err) {
        console.error(err);
      }
    },
    signOut() {
      return firebase.auth().signOut();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .page-content {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
}
.title-container {
  display: flex;
  align-items: center;
}
.signout-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.onboarding-container {
  box-shadow: none;
}
</style>
