<template>
  <div class="project-view">
    <v-row>
      <v-col cols="12" md="6">
        <ProjectSummary class="summary-container" :data="data"></ProjectSummary>
      </v-col>
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col class="mb-3" cols="12">
            <v-card class="price-container pa-3">
              <div>
                <v-icon color="primary">mdi-cash-marker</v-icon>
              </div>
              <div class="price-content">
                <div class="text-body grey--text">{{ priceText }}</div>
                <div class="text-title">{{ panelCost }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" class="calculator-container">
            <RoiCalculator :data="data"></RoiCalculator>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="data.status === 'active'" class="mt-5">
      <div class="title-container mb-2">
        <v-icon color="primary" class="mr-2">mdi-progress-check</v-icon>
        <div class="text-subheading">Project Progress</div>
      </div>
      <ParticipationForm :data="data"></ParticipationForm>
    </div>
    <div class="mt-5">
      <ProjectDetails :data="data"></ProjectDetails>
    </div>
    <div class="mt-5">
      <div class="title-container mb-2">
        <v-icon color="primary" class="mr-2">mdi-clipboard-text</v-icon>
        <div class="text-subheading">Supporting Documents</div>
      </div>
      <DocumentList :data="data"></DocumentList>
    </div>
  </div>
</template>

<script>
import { formatDisplayVal, formatShortAddress } from '@/utils/formats';
import ProjectSummary from './components/ProjectSummary.vue';
import RoiCalculator from './components/RoiCalculator.vue';
import ParticipationForm from './components/ParticipationForm.vue';
import ProjectDetails from './components/ProjectDetails.vue';
import DocumentList from './components/DocumentList.vue';

export default {
  name: 'ProjectParticipationView',
  components: {
    ProjectSummary,
    RoiCalculator,
    ParticipationForm,
    ProjectDetails,
    DocumentList,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      priceText: 'Cost Per Panel',
    };
  },
  computed: {
    moneyFormat() {
      return { format: '0,0', suffix: 'NTD' };
    },
    panelCost() {
      const val = this.data.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
  },
  methods: {
    formatDisplayVal,
    formatShortAddress,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  height: calc(100% - 12px);
}
.price-container {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .price-content {
    text-align: right;
  }
}
.calculator-container {
  height: 100%;
}
.title-container {
  display: flex;
  align-items: center;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
