<template>
  <div>
    <div class="photo-container">
      <v-avatar size="85" class="elevation-3">
        <img aspect-ratio="1" width="85" :src="userInfo.photoUrl" alt="Profile Photo" />
      </v-avatar>
      <div class="text-subheading mt-3">{{ userInfo.displayName }}</div>
    </div>
    <DividerLine />
    <div>
      <div v-for="(item, i) in items" v-bind:key="item.key" @click="setActive(i)" :class="item.active && 'foreground'">
        <router-link :to="item.route" v-ripple="{ class: 'secondary--text' }" class="nav-item">
          <div class="nav-icon">
            <v-icon :color="item.active ? 'black' : 'primary'">{{ item.icon }}</v-icon>
          </div>
          <div :class="item.active ? 'black--text text-subheading' : 'text-body'">
            {{ item.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { placeholderImg } from '@/utils/requests';
import { DividerLine } from '@/components/Divider';

export default {
  name: 'SidePanelContent',
  components: {
    DividerLine,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.items.forEach((item, index) => {
      const routeName = this.$route.name;
      const parentRouteName = routeName.includes('/') ? routeName.substring(0, routeName.indexOf('/')) : routeName;
      if (item.route.name === parentRouteName) {
        this.items[index].active = true;
      } else {
        this.items[index].active = false;
      }
    });
  },
  data() {
    return {
      items: [
        {
          key: 'summary',
          icon: 'mdi-view-dashboard',
          name: 'Summary',
          route: { name: 'summary' },
          active: true,
        },
        {
          key: 'profile',
          icon: 'mdi-account-box',
          name: 'Profile',
          route: { name: 'profile' },
          active: false,
        },
        {
          key: 'projects',
          icon: 'mdi-solar-panel-large',
          name: 'Projects',
          route: { name: 'projects' },
          active: false,
        },
        {
          key: 'messages',
          icon: 'mdi-email-multiple',
          name: 'Messages',
          route: { name: 'messages' },
          active: false,
        },
        {
          key: 'transactions',
          icon: 'mdi-finance',
          name: 'Transactions',
          route: { name: 'messages' },
          active: false,
        },
        {
          key: 'help',
          icon: 'mdi-account-question',
          name: 'Help Centre',
          route: { name: 'help' },
          active: false,
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return {
        photoUrl: placeholderImg('placeholder_profile'),
        displayName: `${this.user.data.firstName} ${this.user.data.lastName}`,
      };
    },
  },
  methods: {
    setActive(activeIndex) {
      this.items.forEach((item, index) => {
        if (index === activeIndex) {
          this.items[index].active = true;
        } else {
          this.items[index].active = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-container {
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nav-item {
  cursor: pointer;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  .nav-icon {
    margin-right: 12px;
  }
}
</style>
