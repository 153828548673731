<template>
  <div class="page-container mb-7">
    <div :style="pageStyle">
      <div class="title-container">
        <div class="text-title mt-3">{{ title }}</div>
        <Button v-if="buttonTitle" @click="$emit('btn-click')">{{ buttonTitle }}</Button>
      </div>
      <div class="text-body mt-2">{{ description }}</div>
      <div v-if="isLoading" class="page-loader">
        <v-progress-circular :size="80" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else-if="isError">error</div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/components/Button';

export default {
  name: 'PageContainer',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 850,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    bp() {
      return this.$vuetify.breakpoint;
    },
    pageWidth() {
      if (this.bp.lgAndUp) return `${this.width}px`;
      return '100%';
    },
    pagePadding() {
      if (this.bp.lgAndUp) return 'unset';
      if (this.bp.smAndUp) return '24px';
      return '12px';
    },
    pageStyle() {
      return {
        width: this.pageWidth,
        padding: this.pagePadding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-container {
  display: flex;
  justify-content: space-between;
}
</style>
