<template>
  <v-row>
    <v-col v-for="item in infoCards" v-bind:key="item.key" cols="6" md="3">
      <v-card height="145" class="pa-3">
        <v-icon color="primary">{{ item.icon }}</v-icon>
        <div class="info-card-container">
          <div class="text-body grey--text" style="text-align: right">{{ item.text }}</div>
          <div class="text-subheading">
            <div v-if="item.subKey" style="text-align: right">{{ getValue(item.subKey) }}</div>
            <div style="text-align: right">{{ getValue(item.key) }}</div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatDisplayVal } from '@/utils/formats';

export default {
  name: 'AssetRoiSummary',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      infoCards: [
        {
          key: 'assetAmount',
          icon: 'mdi-cash-lock',
          text: 'Total Amount Participated',
        },
        {
          key: 'panelCost',
          subKey: 'assetQuantity',
          icon: 'mdi-cash-marker',
          text: 'No. of Panels Purchased',
        },
        {
          key: 'cumulativeReturn',
          icon: 'mdi-cash-multiple',
          text: 'Cumulative Return',
        },
        {
          key: 'upcomingReturn',
          icon: 'mdi-cash-plus',
          text: 'Estimated Upcoming Return',
        },
      ],
    };
  },
  computed: {
    projectDetails() {
      return this.data.project;
    },
    moneyFormat() {
      return { format: '0,0', suffix: 'NTD' };
    },
    panelCost() {
      const val = this.projectDetails.panelCost;
      return formatDisplayVal(val, { format: '0,0', suffix: 'NTD/Panel' });
    },
    assetQuantity() {
      return this.data.quantity;
    },
    assetAmount() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
    cumulativeReturn() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
    upcomingReturn() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
  },
  methods: {
    formatDisplayVal,
    getValue(key) {
      return this[key];
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card-container {
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
