<template>
  <v-app>
    <v-main>
      <div v-if="loading" id="page-loader">
        <v-progress-circular :size="80" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
      <PageLayout v-else-if="showPageLayout">
        <router-view />
      </PageLayout>
      <router-view v-else />
      <UpdateSnackbar />
    </v-main>
  </v-app>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { PageLayout } from '@/features/Layout';
import UpdateSnackbar from '@/features/UpdateSnackbar.vue';

export default {
  name: 'App',
  components: {
    PageLayout,
    UpdateSnackbar,
  },

  created() {
    this.loading = true;
    firebase.auth().onAuthStateChanged((user) => {
      this.authenticated = !!user;
      this.loading = false;
    });
  },

  data() {
    return {
      loading: false,
      authenticated: false,
    };
  },

  computed: {
    // local computed
    showPageLayout() {
      const routeList = ['login', 'signup'];
      return this.authenticated && !routeList.includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Montserrat, Helvetica, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .page-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  .v-btn {
    text-transform: none !important;
  }

  .text-body {
    font-size: 14px;
    color: var(--v-primary-base);
  }
  .text-subheading {
    font-size: 14px;
    font-weight: 600;
    color: var(--v-primary-base);
  }
  .text-title {
    font-size: 32px;
    font-weight: 600;
    color: var(--v-primary-base);
  }
  .text-hint {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.25;
    font-style: italic;
    color: var(--v-primary-base);
  }
  .text-subtitle {
    font-size: 13px;
    color: var(--v-primary-base);
  }
  .text-emphasis {
    font-size: 38px;
    font-weight: 600;
    color: var(--v-primary-base);
  }
}
</style>
