<template>
  <div>
    <v-card class="content elevation-3">
      <div class="title-container mb-5">
        <v-icon class="mr-3" color="primary">{{ titleIcon }}</v-icon>
        <span class="text-subheading">{{ title }}</span>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters class="contact-container">
          <v-col cols="12" md="4" class="pr-3">
            <v-select
              v-model="input.countryCode"
              :items="countryCode.items"
              :label="countryCode.label"
              :placeholder="countryCode.placeholder"
              :rules="countryCode.rules"
              :disabled="loading"
              outlined
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="input.phoneNumber"
              :label="phoneNumber.label"
              :placeholder="phoneNumber.placeholder"
              :rules="phoneNumber.rules"
              :disabled="loading"
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <Status :color="status.color">{{ status.text }}</Status>
    <div class="text-body mb-2">Note: You will receive an SMS message for verification and standard rates apply.</div>
    <div>
      <div id="recaptcha-container"></div>
    </div>
    <div class="buttons">
      <Button color="primary" :disabled="disabled" :loading="loading" @click="verifyMobile">
        {{ buttonNext }}
      </Button>
    </div>
    <v-dialog v-model="codeVerifyDialog" max-width="400px" persistent>
      <v-card class="dialog-container">
        <div class="dialog-close" @click="resetHandler"><v-icon large>mdi-close</v-icon></div>
        <v-form ref="formCodeVerifier">
          <div class="text-title">Verify Code</div>
          <div class="text-body mb-5">Please enter the code sent to ***{{ phoneNumberDisplay }}</div>
          <v-text-field
            v-model="input.code"
            :label="code.label"
            :placeholder="code.placeholder"
            :loading="loading"
            :disabled="loading"
            lazy-validation
            outlined
            required
          ></v-text-field>
          <Button color="primary" class="mt-3" :loading="loading" @click="verifyCodeHandler">Submit code</Button>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { mapActions } from 'vuex';
import { sendCode, verifyCode } from '@/services/functions/auth';
import phoneCountryCodeList from '@/constants/phoneCountryCodeList.json';
import { setError } from '@/utils/errors';
import form from '@/utils/validation/form';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';

export default {
  name: 'MobileVerification',
  components: {
    Status,
    Button,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    firebase.auth().useDeviceLanguage();
  },
  mounted() {
    if (this.user.data.phoneNumberVerified) {
      this.$emit('next', 3);
    }

    // Start Firebase invisible reCAPTCHA verifier
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'normal',
      callback() {
        // reCAPTCHA solved, return token.
      },
    });
  },
  data() {
    return {
      title: 'Verify your mobile device',
      titleIcon: 'mdi-cellphone-key',
      valid: true,
      loading: false,
      disabled: false,
      input: {
        countryCode: '+886',
        phoneNumber: null,
        code: null,
        sessionInfo: null,
      },
      countryCode: {
        label: 'Code',
        items: phoneCountryCodeList.map((item) => ({
          text: `${item.name} (${item.dial_code})`,
          value: item.dial_code,
        })),
        placeholder: '+886',
        rules: [form.required()],
      },
      phoneNumber: {
        label: 'Mobile Number',
        placeholder: null,
        rules: [form.required(), form.number(), form.minLength(), form.maxLength({ len: 15 })],
      },
      codeVerifyDialog: false,
      validCode: true,
      code: {
        label: 'Code',
        placeholder: 'six-digit value',
        rules: [form.required(), form.number(), form.exactLength({ len: 6 })],
      },
      buttonNext: 'Verify',
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    userAuth() {
      return firebase.auth().currentUser;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    phoneNumberDisplay() {
      return this.input.phoneNumber ? this.input.phoneNumber.toString().slice(5) : null;
    },
    fullPhoneNumber() {
      return `${this.input.countryCode}${this.input.phoneNumber}`;
    },
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      updateUser: 'updateUser',
    }),
    // local methods
    validateForm() {
      this.valid = this.$refs.form.validate();
    },
    validateCodeForm() {
      this.validCode = this.$refs.formCodeVerifier.validate();
    },
    async verifyMobile() {
      this.validateForm();
      if (this.valid) {
        try {
          this.disabled = true;
          this.loading = true;

          const appVerifier = window.recaptchaVerifier;
          const recaptchaToken = await appVerifier.verify();
          const res = await sendCode({
            userId: this.userAuth.uid,
            phoneNumber: this.fullPhoneNumber,
            recaptchaToken,
          });
          this.input.sessionInfo = res.data.result.sessionInfo;
          this.codeVerifyDialog = true;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.disabled = false;
          this.status = setError('The phone number is already registered to another account');
        }
      }
    },
    async verifyCodeHandler() {
      this.validateCodeForm();
      if (this.validCode) {
        try {
          this.loading = true;
          const { code, sessionInfo } = this.input;
          await verifyCode({ code, sessionInfo });

          const id = this.userAuth.uid;
          const param = {
            phoneNumber: this.fullPhoneNumber,
            phoneNumberVerified: true,
          };
          await this.updateUser({ id, ...param });
          this.codeVerifyDialog = false;
          this.$emit('next', 3);
        } catch (err) {
          this.loading = false;
          this.disabled = false;
          this.status = setError('The phone number is already registered to another account');
        }
      }
    },
    resetHandler() {
      this.loading = false;
      this.codeVerifyDialog = false;
      window.recaptchaVerifier.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
}
.content {
  margin-bottom: 24px;
  padding: 24px;
}
.buttons {
  display: flex;
  &:not(:last-child) {
    margin-right: 12px;
  }
}
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  .dialog-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
}
</style>
