import { camelToSnake } from '@/utils/formats';

export const setCreateParam = (param) => {
  const currentDate = new Date();

  return camelToSnake({
    ...param,
    createdAt: currentDate,
    updatedAt: currentDate,
  });
};

export const setUpdateParam = (param) => {
  const currentDate = new Date();

  return camelToSnake({
    ...param,
    updatedAt: currentDate,
  });
};

export const mutations = {
  SET_LOADING(state, payload) {
    state[payload.state] = {
      error: false,
      loading: true,
    };
  },
  SET_ERROR(state, payload) {
    state[payload.state] = {
      error: true,
      loading: false,
    };
  },
  SET_DATA_REPLACE(state, payload) {
    state[payload.state] = {
      loading: false,
      error: false,
      data: payload.payload,
    };
  },
  SET_DATA_UPDATED(state, payload) {
    state[payload.state].data = {
      ...state[payload.state].data,
      ...payload,
    };
  },
};
