<template>
  <div class="list-container" :style="containerStyle">
    <v-row>
      <v-col v-for="item in items" v-bind:key="item._id" cols="12" md="6">
        <v-hover v-slot="{ hover }">
          <v-card
            @click="$emit('item-click', item)"
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            :max-width="maxWidth"
          >
            <v-img :height="imgHeight" :src="item.displayImgs[0].url" class="black">
              <div class="text-in-img">
                <div class="text-subheading white--text">{{ estimatedIrrText }}</div>
                <div class="text-emphasis white--text">
                  {{ formatDisplayVal(item.estimatedIrr, { format: '0.00%' }) }}
                </div>
              </div>
            </v-img>
            <div class="pa-5">
              <div class="flex-container text-subheading black--text mb-4">
                <div>{{ item.name }}</div>
                <div>{{ getCityDisplay(item.address) }}</div>
              </div>
              <div
                v-for="{ key, text, format, prefix, suffix } in keys"
                v-bind:key="key"
                class="flex-container text-body black--text mb-1"
              >
                <div>{{ text }}</div>
                <div>{{ formatDisplayVal(item[key], { format, prefix, suffix }) }}</div>
              </div>
              <!-- <div class="flex-container text-body black--text mb-1">
                <div>{{ projectTenure }}</div>
                <div>{{ getProjectTenure(item) }}</div>
              </div> -->
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDisplayVal } from '@/utils/formats';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    estimatedIrrText: 'Estimated IRR',
    projectTenure: 'Project Length',
  }),
  computed: {
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    isMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    containerStyle() {
      return {
        minWidth: `${this.isXs ? this.maxWidth : this.maxWidth * 2}px`,
      };
    },
    maxWidth() {
      return this.isMdAndUp ? 400 : 320;
    },
    imgHeight() {
      return 120;
    },
  },
  methods: {
    formatDisplayVal,
    getCityDisplay(address) {
      return address.city;
    },
    getProjectTenure(item) {
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-wrap: wrap;
  .v-card {
    transition: all 0.2s ease-out;
    cursor: pointer;
  }
  .v-card:not(.on-hover) {
    transform: scale(1);
    opacity: 0.85;
  }
  .v-card.on-hover {
    transform: scale(1.01);
    opacity: 1;
  }
}
.text-in-img {
  position: absolute;
  right: 12px;
  bottom: 12px;
  text-align: right;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
