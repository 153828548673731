<template>
  <div>
    <v-card class="content elevation-3">
      <div class="title-container mb-5">
        <v-icon class="mr-3" color="primary">{{ titleIcon }}</v-icon>
        <span class="text-subheading">{{ title }}</span>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-col
            v-for="{ type, key, cols, label, items, onChange, rules } in fields"
            v-bind:key="key"
            cols="12"
            :md="cols"
            :class="(isXs || cols < 10) && 'mr-2'"
          >
            <component
              v-model="input[key]"
              :is="type"
              :label="label"
              :rules="rules"
              :disabled="loading"
              :items="items"
              v-on:change="onChange && onChange($event)"
              lazy-validation
              outlined
              required
            ></component>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5 mb-5">
          <v-col v-for="{ key, label, placeholder } in pictureUploads" v-bind:key="key" cols="12" md="5" class="mr-2">
            <div class="title-container mb-5">
              <v-icon class="mr-3" color="primary">mdi-image-area</v-icon>
              <span class="text-subheading">{{ label }}</span>
            </div>
            <UploadPhoto
              @uploaded="pictureUploadedHandler"
              :imgKey="key"
              :label="label"
              :src="input[key]"
              :placeholder="placeholder"
              :path="getImgPath(key)"
              bucket="users"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <Status :color="status.color">{{ status.text }}</Status>
    <div class="buttons">
      <Button @click="handleNext" :loading="loading" color="primary">{{ buttonNext }}</Button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from '@/services/firebase';
import twBankList from '@/constants/banks/TW.json';
import { setError } from '@/utils/errors';
import form from '@/utils/validation/form';
import { toSnake } from '@/utils/formats';
import { placeholderImg } from '@/utils/requests';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';
import UploadPhoto from './UploadPhoto.vue';

export default {
  name: 'BankInformation',
  components: {
    Status,
    Button,
    UploadPhoto,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const user = this.user.data;
    this.input = {
      bankName: user.bankName || null,
      bankCode: user.bankCode || null,
      bankBranchName: user.bankBranchName || null,
      accountNumber: user.accountNumber || null,
      accountName: user.accountName || null,
      bankPassbook: user.bankPassbook || null,
      profileStatus: 'pending_approval',
    };
    const complete = Object.keys(this.input).every((field) => this.input[field] !== null);
    if (complete) {
      this.$emit('next', 5);
    }
  },
  data() {
    return {
      title: 'Choose a bank account for your transactional activities in Lyght',
      titleIcon: 'mdi-bank',
      valid: true,
      loading: false,
      input: {
        bankName: null,
        bankCode: null,
        bankBranchName: null,
        accountNumber: null,
        accountName: null,
        bankPassbook: null,
        profileStatus: null,
      },
      fields: [
        {
          type: 'v-select',
          cols: '6',
          key: 'bankName',
          label: 'Bank Name',
          items: twBankList.map(({ name }) => name),
          onChange: (v) => {
            this.input.bankCode = twBankList.find(({ name }) => name === v).code;
          },
          rules: [form.required()],
        },
        {
          type: 'v-select',
          cols: '4',
          key: 'bankCode',
          label: 'Bank Code',
          items: twBankList.map(({ code }) => code),
          onChange: (v) => {
            this.input.bankName = twBankList.find(({ code }) => code === v).name;
          },
          rules: [form.required(), form.number()],
        },
        {
          type: 'v-text-field',
          cols: '6',
          key: 'bankBranchName',
          label: 'Bank Branch Name',
          rules: [form.required()],
        },
        {
          type: 'v-text-field',
          cols: '6',
          key: 'accountNumber',
          label: 'Account Number',
          rules: [form.required(), form.number()],
        },
        {
          type: 'v-text-field',
          cols: '4',
          key: 'accountName',
          label: 'Account Name',
          rules: [form.required()],
        },
      ],
      fileUpload: {
        key: '',
        index: '',
      },
      pictureUploads: [
        {
          key: 'bankPassbook',
          label: 'Upload the Cover Page of your Bankbook',
          placeholder: placeholderImg('placeholder_bank-book'),
        },
      ],
      buttonNext: 'Continue',
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    userAuth() {
      return firebase.auth().currentUser;
    },
    storageRef() {
      return firebase.storage().ref('users');
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      updateUser: 'updateUser',
    }),
    // local methods
    getImgPath(key) {
      return `${this.userAuth.uid}/${toSnake(key)}.jpg`;
    },
    pictureUploadedHandler({ key, url }) {
      this.input[key] = url;
    },
    validate() {
      this.valid = this.$refs.form.validate();
    },
    async handleNext() {
      this.validate();
      if (this.valid) {
        try {
          this.loading = true;
          const id = this.userAuth.uid;
          await this.updateUser({ id, ...this.input });
          this.$emit('next', 5);
        } catch (err) {
          this.loading = false;
          this.status = setError(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
}
.content {
  margin-bottom: 24px;
  padding: 24px;
}
.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  cursor: pointer;

  .img-container {
    position: relative;
    width: 150px;
    height: 100px;
  }
}
.img-container {
  position: relative;
  width: 300px;
  height: 200px;
}
.picture-uploading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 200px;
  width: 300px;
}
.img-preview {
  cursor: pointer;
}
.buttons {
  display: flex;
  &:not(:last-child) {
    margin-right: 12px;
  }
}
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  .dialog-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
}
</style>
