<template>
  <div>
    <div class="text-title">Messages</div>
  </div>
</template>

<script>
export default {
  name: 'Messages',
};
</script>
