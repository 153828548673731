<template>
  <PageContainer :title="title" :description="description" :isLoading="isLoading" :isError="isError">
    <div v-if="hasData(activeUserProjectList)">
      <div class="text-subheading mt-5">My Projects</div>
      <ProjectList @item-click="routeToUserProject" :items="userProjectList" :keys="keys" />
    </div>

    <div v-if="hasData(activeProjectList)">
      <div class="text-subheading mt-5">Available Projects</div>
      <ProjectList @item-click="routeToActiveProject" :items="activeProjectList.data" :keys="keys" />
    </div>

    <div v-if="hasData(newProjectList)">
      <div class="text-subheading mt-5">New/Upcoming Projects</div>
      <ProjectList @item-click="routeToNewProject" :items="newProjectList.data" :keys="keys" />
    </div>
  </PageContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PageContainer } from '@/features/Layout';
import ProjectList from '@/features/Projects/ProjectList.vue';

export default {
  name: 'Projects',
  components: {
    PageContainer,
    ProjectList,
  },
  created() {
    this.getActiveUserProjects(this.user.data._id);
    this.getActiveProjects();
    this.getNewProjects();
  },
  data() {
    return {
      title: 'Projects',
      description: 'List of Projects',
      renderers: ['activeUserProjectList', 'activeProjectList', 'newProjectList'],
      keys: [
        {
          key: 'establishedDate',
          text: 'Farm establishment date',
        },
        {
          key: 'estimatedIrr',
          text: 'Estimated IRR',
          format: '0.00%',
        },
        {
          key: 'capacity',
          text: 'Farm Capacity',
          suffix: 'kW',
        },
        {
          key: 'panelsTotal',
          text: 'Total no. of panels',
        },
        // TODO: add tenure
        // {
        //   key: 'tenure',
        //   text: 'Project Tenure',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('users', {
      user: 'user',
    }),
    ...mapGetters('projects', {
      activeUserProjectList: 'activeUserProjectList',
      activeProjectList: 'activeProjectList',
      newProjectList: 'newProjectList',
    }),
    isLoading() {
      return this.renderers.some((r) => this[r].loading);
    },
    isError() {
      return this.renderers.some((r) => this[r].error);
    },
    userProjectList() {
      return this.activeUserProjectList.data.map((d) => d.project);
    },
  },
  methods: {
    ...mapActions('projects', {
      getActiveUserProjects: 'getActiveUserProjects',
      getActiveProjects: 'getActiveProjects',
      getNewProjects: 'getNewProjects',
    }),
    hasData(state) {
      return state.data && state.data.length > 0;
    },
    routeToUserProject(item) {
      this.$router.push({ name: 'projects/invested/:id', params: { id: item._id } });
    },
    routeToActiveProject(item) {
      this.$router.push({ name: 'projects/active/:id', params: { id: item._id } });
    },
    routeToNewProject(item) {
      this.$router.push({ name: 'projects/new/:id', params: { id: item._id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    max-width: 850px;
  }
}
</style>
