import axios from 'axios';
import { api } from './config';

export const sendCode = (params) => {
  return axios.post(`${api}/auth/phone/sendCode`, {
    userId: params.userId,
    phoneNumber: params.phoneNumber,
    recaptchaToken: params.recaptchaToken,
  });
};

export const verifyCode = (params) => {
  return axios.post(`${api}/auth/phone/verifyCode`, {
    code: params.code,
    sessionInfo: params.sessionInfo,
  });
};
