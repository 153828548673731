export const translate = (trans, lang = 'en') => trans[lang];

export const translateObj = (data = {}, lang = 'en') => {
  const keys = Object.keys(data);
  const result = {};
  keys.forEach((key) => {
    if (key.includes('_trans')) {
      result[key] = translate(data[key], lang);
    } else {
      result[key] = data[key];
    }
  });
  return result;
};

export const getTranslationList = (list, textField = 'name_trans', valueField = 'value') =>
  list.map((item) => ({
    // TODO -- add locale translations
    text: item[textField].en,
    value: item[valueField],
  }));
