<template>
  <div class="project-view">
    <AssetRoiSummary :data="data"></AssetRoiSummary>
    <v-row>
      <v-col cols="12" md="6">
        <ProjectSummary class="summary-container" :data="projectDetails"></ProjectSummary>
      </v-col>
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col cols="12">
            <CurrentWeather :data="projectDetails"></CurrentWeather>
          </v-col>
          <v-col cols="12">
            <AssetRoiDetails :data="data"></AssetRoiDetails>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="mt-5">
      <ProjectDetails :data="projectDetails" :defaultExpand="false"></ProjectDetails>
    </div>
    <div class="mt-5">
      <div class="title-container mb-2">
        <v-icon color="primary" class="mr-2">mdi-clipboard-text</v-icon>
        <div class="text-subheading">Supporting Documents</div>
      </div>
      <DocumentList :data="projectDetails"></DocumentList>
    </div>
  </div>
</template>

<script>
import { formatDisplayVal, formatShortAddress } from '@/utils/formats';
import AssetRoiSummary from './components/AssetRoiSummary.vue';
import AssetRoiDetails from './components/AssetRoiDetails.vue';
import ProjectSummary from './components/ProjectSummary.vue';
import CurrentWeather from './components/CurrentWeather.vue';
import ProjectDetails from './components/ProjectDetails.vue';
import DocumentList from './components/DocumentList.vue';

export default {
  name: 'ProjectParticipationView',
  components: {
    AssetRoiSummary,
    AssetRoiDetails,
    ProjectSummary,
    CurrentWeather,
    ProjectDetails,
    DocumentList,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      priceText: 'Cost Per Panel',
    };
  },
  computed: {
    projectDetails() {
      return this.data.project;
    },
    moneyFormat() {
      return { format: '0,0', suffix: 'NTD' };
    },
    panelCost() {
      const val = this.projectDetails.panelCost;
      return formatDisplayVal(val, { format: '0,0', suffix: 'NTD/Panel' });
    },
  },
  methods: {
    formatDisplayVal,
    formatShortAddress,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  height: calc(100% - 12px);
}
.price-container {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .price-content {
    text-align: right;
  }
}
.title-container {
  display: flex;
  align-items: center;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
