<template>
  <v-row>
    <v-col v-for="doc in data.documents" v-bind:key="doc.url" cols="6" md="3">
      <a target="_blank" :href="doc.url" style="text-decoration: none">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 6 : 2" :class="{ 'on-hover': hover }" class="document-container pa-3">
            <div class="text-subheading black--text mb-3">{{ doc.name }}</div>
            <v-icon x-large color="primary">mdi-file-document-outline</v-icon>
            <div class="text-body grey--text mt-1">{{ viewFileText }}</div>
          </v-card>
        </v-hover>
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DocumentList',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewFileText: 'View PDF',
    };
  },
};
</script>

<style lang="scss" scoped>
.document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.v-card {
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.v-card:not(.on-hover) {
  transform: scale(1);
  opacity: 0.85;
}
.v-card.on-hover {
  transform: scale(1.01);
  opacity: 1;
}
</style>
