<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="text-title">{{ title }}</div>
    <div class="text-body">{{ caption }}</div>
    <div class="social-login">
      <FacebookLoginButton class="btn" @error="onSocialLoginError" />
      <GoogleLoginButton class="btn" @error="onSocialLoginError" />
      <Status :color="socialLoginStatus.color">{{ socialLoginStatus.text }}</Status>
    </div>
    <DividerLineWithText class="mt-5">OR</DividerLineWithText>
    <div class="userpass-login">
      <v-text-field
        v-model="email.value"
        :label="email.label"
        :rules="email.rules"
        :disabled="loading"
        validate-on-blur
        outlined
        required
      ></v-text-field>
      <v-text-field
        v-model="password.value"
        :label="password.label"
        :rules="password.rules"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :disabled="loading"
        @click:append="showPassword = !showPassword"
        outlined
        required
      ></v-text-field>
    </div>
    <Status :color="status.color">{{ status.text }}</Status>
    <Button @click="submitFormHandler" :loading="loading">Login</Button>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from '@/services/firebase';
import { setError } from '@/utils/errors';
import form from '@/utils/validation/form';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';
import { FacebookLoginButton, GoogleLoginButton } from '@/features/Auth';
import { DividerLineWithText } from '@/components/Divider';

export default {
  name: 'Login',
  components: {
    Status,
    Button,
    FacebookLoginButton,
    GoogleLoginButton,
    DividerLineWithText,
  },
  data() {
    return {
      valid: true,
      title: 'Member Login',
      caption: 'Sign in to Lyght with social media or email',
      email: {
        value: '',
        label: 'Email',
        rules: [form.required(), form.email()],
      },
      password: {
        value: '',
        label: 'Password',
        rules: [form.required()],
      },
      showPassword: false,
      loading: false,
      status: {
        text: '',
        color: '',
      },
      socialLoginStatus: {
        text: '',
        color: '',
      },
    };
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      createUser: 'createUser',
    }),

    // local methods
    validate() {
      this.valid = this.$refs.form.validate();
    },
    async submitFormHandler() {
      this.validate();
      if (this.valid) {
        try {
          this.loading = true;
          await this.login();
          this.$router.replace({ name: 'onboarding ' });
        } catch (err) {
          this.loading = false;
          this.status = setError('Email and/or password is invalid', false);
        }
      }
    },
    login() {
      return firebase.auth().signInWithEmailAndPassword(this.email.value, this.password.value);
    },
    sendResetPasswordEmail() {
      return firebase.auth().sendPasswordResetEmail(this.email.value);
    },
    onSocialLoginError(err) {
      this.socialLoginStatus = setError(err);
    },
  },
};
</script>

<style lang="scss" scoped>
.social-login {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .btn {
    margin-top: 16px;
  }
}

.userpass-login {
  margin-top: 32px;
}
</style>
