var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"content elevation-3"},[_c('div',{staticClass:"title-container mb-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.generalTitleIcon))]),_c('span',{staticClass:"text-subheading"},[_vm._v(_vm._s(_vm.generalTitle))])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.nameFields),function(ref){
var key = ref.key;
var label = ref.label;
var rules = ref.rules;
return _c('v-col',{key:key,staticClass:"mr-2",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":label,"rules":rules,"disabled":_vm.loading,"lazy-validation":"","outlined":"","required":""},model:{value:(_vm.input[key]),callback:function ($$v) {_vm.$set(_vm.input, key, $$v)},expression:"input[key]"}})],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[[_c('v-menu',{ref:"dobMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.dob.label,"lazy-validation":"","readonly":"","outlined":""},model:{value:(_vm.input.dob),callback:function ($$v) {_vm.$set(_vm.input, "dob", $$v)},expression:"input.dob"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dobMenu),callback:function ($$v) {_vm.dobMenu=$$v},expression:"dobMenu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.dob.max,"min":_vm.dob.min},on:{"change":_vm.saveDate},model:{value:(_vm.input.dob),callback:function ($$v) {_vm.$set(_vm.input, "dob", $$v)},expression:"input.dob"}})],1)]],2)],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.identityFields),function(ref){
var key = ref.key;
var label = ref.label;
var type = ref.type;
var items = ref.items;
var rules = ref.rules;
var disabled = ref.disabled;
return _c('v-col',{key:key,staticClass:"mr-2",attrs:{"cols":"12","md":"5"}},[_c(type,{tag:"component",attrs:{"label":label,"rules":rules,"disabled":disabled || _vm.loading,"items":items,"lazy-validation":"","outlined":"","required":""},model:{value:(_vm.input[key]),callback:function ($$v) {_vm.$set(_vm.input, key, $$v)},expression:"input[key]"}})],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"text-body"},[_vm._v(_vm._s(_vm.usCitizenship.label))]),_c('v-radio-group',{attrs:{"mandatory":"","row":""},model:{value:(_vm.input.usCitizenship),callback:function ($$v) {_vm.$set(_vm.input, "usCitizenship", $$v)},expression:"input.usCitizenship"}},_vm._l((_vm.usCitizenship.choices),function(choice){return _c('v-radio',{key:choice.value,attrs:{"label":choice.label,"value":choice.value}})}),1)],1)],1),_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"no-gutters":""}},_vm._l((_vm.pictureUploads),function(ref){
var key = ref.key;
var label = ref.label;
var placeholder = ref.placeholder;
return _c('v-col',{key:key,staticClass:"mr-2",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"title-container mb-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-image-area")]),_c('span',{staticClass:"text-subheading"},[_vm._v(_vm._s(label))])],1),_c('UploadPhoto',{attrs:{"imgKey":key,"label":label,"src":_vm.input[key],"placeholder":placeholder,"path":_vm.getImgPath(key),"bucket":"users"},on:{"uploaded":_vm.pictureUploadedHandler}})],1)}),1),_vm._l((_vm.addressComponents),function(ref){
var title = ref.title;
var titleIcon = ref.titleIcon;
var fields = ref.fields;
return _c('div',{key:title},[_c('DividerLine',{staticClass:"mb-5"}),_c('div',{staticClass:"title-container mb-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v(_vm._s(titleIcon))]),_c('span',{staticClass:"text-subheading"},[_vm._v(_vm._s(title))])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((fields),function(ref){
var type = ref.type;
var key = ref.key;
var cols = ref.cols;
var label = ref.label;
var items = ref.items;
var rules = ref.rules;
var disabled = ref.disabled;
return _c('v-col',{key:key,staticClass:"mr-2",attrs:{"cols":"12","md":cols}},[_c(type,{tag:"component",attrs:{"label":label,"rules":rules,"disabled":disabled || _vm.loading,"items":items,"lazy-validation":"","outlined":"","required":""},model:{value:(_vm.input[key]),callback:function ($$v) {_vm.$set(_vm.input, key, $$v)},expression:"input[key]"}})],1)}),1)],1)})],2)],1),_c('Status',{attrs:{"color":_vm.status.color}},[_vm._v(_vm._s(_vm.status.text))]),_c('div',{staticClass:"buttons"},[_c('Button',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.handleNext}},[_vm._v(_vm._s(_vm.buttonNext))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }