import numeral from 'numeral';

const isObject = (test) => typeof test === 'object' && test !== null && !Array.isArray(test) && !(test instanceof Date);

const isWhiteListed = (key) => key === '_id' || key.includes('_trans');

export const toSnake = (str) =>
  isWhiteListed(str) ? str : str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toCamel = (str) =>
  isWhiteListed(str)
    ? str
    : str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const loopObject = (obj, fn) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (isObject(value)) {
      newObj[fn(key)] = loopObject(value, fn);
    } else {
      newObj[fn(key)] = value;
    }
  });
  return newObj;
};

export const camelToSnake = (obj) => loopObject(obj, toSnake);

export const snakeToCamel = (obj) => loopObject(obj, toCamel);

export const formatDisplayVal = (val, config) => {
  const { format, prefix, suffix } = config;
  const prefixVal = prefix ? `${prefix} ` : '';
  const suffixVal = suffix ? ` ${suffix}` : '';
  if (format) {
    return `${prefixVal}${numeral(val).format(format)}${suffixVal}`;
  }
  return `${prefixVal}${val}${suffixVal}`;
};

export const formatAddress = (address) => {
  const { address1, address2, district, city, country } = address;
  return `${address2}, ${address1}, ${district}, ${city}, ${country}`;
};

export const formatShortAddress = (address) => {
  const { district, city, country } = address;
  return `${district}, ${city}, ${country}`;
};
