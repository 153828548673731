<template>
  <div v-if="$route.name">
    <Header @click="drawer = true" />
    <div v-if="isLoading" class="page-loader">
      <v-progress-circular :size="80" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <v-card v-bind:style="sidePanelContainerStyle" flat tile>
        <SidePanel v-if="showPanel && isMdAndUp" :user="user" :offsetTop="headerHeight" />
        <SidePanelToggle
          v-else-if="showPanel && !isMdAndUp"
          :open="drawer"
          :user="user"
          @open-update="updateDrawer"
          :offsetTop="headerHeight"
        />
        <div v-bind:style="contentStyle">
          <slot></slot>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import firebase from '@/services/firebase';
import Header from './Header.vue';
import SidePanel from './SidePanel.vue';
import SidePanelToggle from './SidePanelToggle.vue';

export default {
  name: 'PageLayout',
  components: {
    SidePanel,
    SidePanelToggle,
    Header,
  },
  created() {
    this.getUser(firebase.auth().currentUser.uid);
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    // vuex computed
    ...mapGetters('users', {
      user: 'user',
    }),
    // local computed
    isMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isLoading() {
      if (this.user.data) {
        if (this.$route.name !== 'onboarding' && this.user.data.profileStatus !== 'approved') {
          this.$router.replace({ name: 'onboarding' });
        } else if (this.$route.name === 'onboarding' && this.user.data.profileStatus === 'approved') {
          this.$router.replace({ name: 'summary' });
        }
      }
      return this.user.loading;
    },
    headerHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 56 : 64;
    },
    sidePanelWidth() {
      return 256;
    },
    showPanel() {
      return this.$route.name !== 'onboarding';
    },
    sidePanelContainerStyle() {
      return {
        position: 'relative',
        height: `calc(100vh - ${this.headerHeight}px)`,
      };
    },
    contentStyle() {
      return {
        margin:
          this.isMdAndUp && this.showPanel
            ? `${this.headerHeight}px 0 0 ${this.sidePanelWidth}px`
            : `${this.headerHeight}px 0 0 0`,
        height: `calc(100vh - ${this.headerHeight}px)`,
      };
    },
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      getUser: 'getUser',
    }),
    // local methods
    updateDrawer(val) {
      this.drawer = val;
    },
  },
};
</script>
