<template>
  <PageContainer :title="title" :description="description" :isLoading="isLoading" :isError="isError">
    <ProjectParticipationView :data="projectDetails.data"></ProjectParticipationView>
  </PageContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatShortAddress } from '@/utils/formats';
import { PageContainer } from '@/features/Layout';
import ProjectParticipationView from '@/features/Projects/ProjectParticipationView.vue';

export default {
  name: 'ActiveProjectView',
  components: {
    PageContainer,
    ProjectParticipationView,
  },
  created() {
    this.getProject(this.$route.params.id);
    this.getProjectDetails(this.$route.params.id);
  },
  data() {
    return {
      renderers: ['projectDetails'],
      lang: 'en',
    };
  },
  computed: {
    ...mapGetters('projects', {
      projectDetails: 'projectDetails',
    }),
    isLoading() {
      return this.renderers.some((r) => this[r].loading);
    },
    isError() {
      return this.renderers.some((r) => this[r].error);
    },
    title() {
      return !this.isLoading && !this.isError ? this.projectDetails.data.name : '';
    },
    description() {
      return !this.isLoading && !this.isError ? formatShortAddress(this.projectDetails.data.address) : '';
    },
  },
  methods: {
    ...mapActions('projects', {
      getProject: 'getProject',
      getProjectDetails: 'getProjectDetails',
    }),
  },
};
</script>
