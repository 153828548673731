<template>
  <div>
    <v-card class="content elevation-3">
      <div class="title-container mb-5">
        <v-icon class="mr-3" color="primary">{{ generalTitleIcon }}</v-icon>
        <span class="text-subheading">{{ generalTitle }}</span>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-col v-for="{ key, label, rules } in nameFields" v-bind:key="key" cols="12" md="5" class="mr-2">
            <v-text-field
              v-model="input[key]"
              :label="label"
              :rules="rules"
              :disabled="loading"
              lazy-validation
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <template>
              <v-menu
                ref="dobMenu"
                v-model="dobMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.dob"
                    :label="dob.label"
                    v-on="on"
                    v-bind="attrs"
                    lazy-validation
                    readonly
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="input.dob"
                  :max="dob.max"
                  :min="dob.min"
                  @change="saveDate"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            v-for="{ key, label, type, items, rules, disabled } in identityFields"
            v-bind:key="key"
            cols="12"
            md="5"
            class="mr-2"
          >
            <component
              v-model="input[key]"
              :is="type"
              :label="label"
              :rules="rules"
              :disabled="disabled || loading"
              :items="items"
              lazy-validation
              outlined
              required
            ></component>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <div class="text-body">{{ usCitizenship.label }}</div>
            <v-radio-group v-model="input.usCitizenship" mandatory row>
              <v-radio
                v-for="choice in usCitizenship.choices"
                v-bind:key="choice.value"
                :label="choice.label"
                :value="choice.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5 mb-5">
          <v-col v-for="{ key, label, placeholder } in pictureUploads" v-bind:key="key" cols="12" md="5" class="mr-2">
            <div class="title-container mb-5">
              <v-icon class="mr-3" color="primary">mdi-image-area</v-icon>
              <span class="text-subheading">{{ label }}</span>
            </div>
            <UploadPhoto
              @uploaded="pictureUploadedHandler"
              :imgKey="key"
              :label="label"
              :src="input[key]"
              :placeholder="placeholder"
              :path="getImgPath(key)"
              bucket="users"
            />
          </v-col>
        </v-row>
        <div v-for="{ title, titleIcon, fields } in addressComponents" v-bind:key="title">
          <DividerLine class="mb-5" />
          <div class="title-container mb-5">
            <v-icon class="mr-3" color="primary">{{ titleIcon }}</v-icon>
            <span class="text-subheading">{{ title }}</span>
          </div>
          <v-row no-gutters>
            <v-col
              v-for="{ type, key, cols, label, items, rules, disabled } in fields"
              v-bind:key="key"
              cols="12"
              :md="cols"
              class="mr-2"
            >
              <component
                v-model="input[key]"
                :is="type"
                :label="label"
                :rules="rules"
                :disabled="disabled || loading"
                :items="items"
                lazy-validation
                outlined
                required
              ></component>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
    <Status :color="status.color">{{ status.text }}</Status>
    <div class="buttons">
      <Button :loading="loading" @click="handleNext" color="primary">{{ buttonNext }}</Button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from '@/services/firebase';
import nationalityList from '@/constants/nationalityList.json';
import countryList from '@/constants/countryList.json';
import { toSnake } from '@/utils/formats';
import { setError } from '@/utils/errors';
import { placeholderImg } from '@/utils/requests';
import form from '@/utils/validation/form';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';
import { DividerLine } from '@/components/Divider';
import UploadPhoto from './UploadPhoto.vue';

export default {
  name: 'PersonalInformation',
  components: {
    Status,
    Button,
    DividerLine,
    UploadPhoto,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const user = this.user.data;
    this.input = {
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      dob: user.dob || null,
      nationality: user.nationality || 'Taiwanese',
      usCitizenship: user.usCitizenship || false,
      identificationNumber: user.identificationNumber || null,
      identificationFrontPhoto: user.identificationFrontPhoto || null,
      identificationBackPhoto: user.identificationBackPhoto || null,
      registeredAddress1: user.registeredAddress1 || null,
      registeredAddress2: user.registeredAddress2 || null,
      registeredAddressCountry: user.registeredAddressCountry || 'TW',
      registeredAddressCode: user.registeredAddressCode || null,
      sameAsRegistered: false,
      residentialAddress1: user.residentialAddress1 || null,
      residentialAddress2: user.residentialAddress2 || null,
      residentialAddressCountry: user.residentialAddressCountry || 'TW',
      residentialAddressCode: user.residentialAddressCode || null,
    };

    const complete = Object.keys(this.input).every((field) => this.input[field] !== null);
    if (complete) {
      this.$emit('next', 4);
    }
  },
  watch: {
    dobMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
    },
    input: {
      handler(val) {
        if (val.sameAsRegistered === true) {
          this.input.residentialAddress1 = this.input.registeredAddress1;
          this.input.residentialAddress2 = this.input.registeredAddress2;
          this.input.residentialAddressCountry = this.input.registeredAddressCountry;
          this.input.residentialAddressCode = this.input.registeredAddressCode;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      generalTitle: 'General information',
      generalTitleIcon: 'mdi-account-circle',
      valid: true,
      loading: false,
      input: {
        firstName: null,
        lastName: null,
        dob: null,
        nationality: 'Taiwanese',
        usCitizenship: false,
        identificationNumber: null,
        identificationFrontPhoto: null,
        identificationBackPhoto: null,
        registeredAddress1: null,
        registeredAddress2: null,
        registeredAddressCountry: 'TW',
        registeredAddressCode: null,
        sameAsRegistered: false,
        residentialAddress1: null,
        residentialAddress2: null,
        residentialAddressCountry: 'TW',
        residentialAddressCode: null,
      },
      nameFields: [
        {
          key: 'firstName',
          label: 'First Name',
          rules: [form.required()],
        },
        {
          key: 'lastName',
          label: 'Last Name',
          rules: [form.required()],
        },
      ],
      dobMenu: false,
      dob: {
        menu: false,
        label: 'Date of birth',
        max: new Date(new Date().getTime() - 20 * 365 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
        min: new Date(new Date().getTime() - 100 * 365 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
      },
      identityFields: [
        {
          type: 'v-text-field',
          key: 'identificationNumber',
          label: 'Identification (ID) Number',
          rules: [form.required()],
        },
        {
          type: 'v-select',
          key: 'nationality',
          label: 'Nationality',
          items: nationalityList,
          rules: [form.required()],
        },
      ],
      usCitizenship: {
        label: 'Do you have US citizenship?',
        choices: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      pictureUploads: [
        {
          key: 'identificationFrontPhoto',
          label: 'Upload front image of ID card',
          placeholder: placeholderImg('placeholder_id-front'),
        },
        {
          key: 'identificationBackPhoto',
          label: 'Upload back image of ID card',
          placeholder: placeholderImg('placeholder_id-back'),
        },
      ],
      addressComponents: [
        {
          title: 'Registered Address',
          titleIcon: 'mdi-book-account',
          fields: [
            {
              type: 'v-text-field',
              cols: '10',
              key: 'registeredAddress1',
              label: 'Address Line 1',
              rules: [form.required()],
            },
            {
              type: 'v-text-field',
              cols: '10',
              key: 'registeredAddress2',
              label: 'Address Line 2',
            },
            {
              type: 'v-select',
              cols: '5',
              key: 'registeredAddressCountry',
              label: 'Country',
              items: countryList.map((item) => ({ text: item.name, value: item.code })),
              rules: [form.required()],
              disabled: true,
            },
            {
              type: 'v-text-field',
              cols: '3',
              key: 'registeredAddressCode',
              label: 'Postal Code',
              rules: [form.required()],
            },
          ],
        },
        {
          title: 'Residential Address',
          titleIcon: 'mdi-book-account',
          fields: [
            {
              type: 'v-checkbox',
              key: 'sameAsRegistered',
              label: 'Same as registered address?',
            },
            {
              type: 'v-text-field',
              cols: '10',
              key: 'residentialAddress1',
              label: 'Address Line 1',
              rules: [form.required()],
            },
            {
              type: 'v-text-field',
              cols: '10',
              key: 'residentialAddress2',
              label: 'Address Line 2',
            },
            {
              type: 'v-select',
              cols: '5',
              key: 'residentialAddressCountry',
              label: 'Country',
              items: countryList.map((item) => ({ text: item.name, value: item.code })),
              rules: [form.required()],
              disabled: true,
            },
            {
              type: 'v-text-field',
              cols: '3',
              key: 'residentialAddressCode',
              label: 'Postal Code',
              rules: [form.required()],
            },
          ],
        },
      ],
      buttonNext: 'Continue',
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    userAuth() {
      return firebase.auth().currentUser;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    nextDisabled() {
      return !this.userAuth.emailVerified;
    },
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      updateUser: 'updateUser',
    }),
    // local methods
    validate() {
      this.valid = this.$refs.form.validate();
    },
    saveDate(date) {
      this.$refs.dobMenu.save(date);
    },
    getImgPath(key) {
      return `${this.userAuth.uid}/${toSnake(key)}.jpg`;
    },
    pictureUploadedHandler({ key, url }) {
      this.input[key] = url;
    },
    async handleNext() {
      this.validate();
      const hasPhoto = this.input.identificationFrontPhoto && this.input.identificationBackPhoto;
      if (!hasPhoto) {
        this.status = setError('Identification photos are required');
      } else if (!this.input.dob) {
        this.status = setError('Date of birth is required');
      } else if (this.valid && hasPhoto) {
        this.loading = true;
        try {
          const id = this.userAuth.uid;
          const { sameAsRegistered, ...param } = this.input;
          await this.updateUser({ id, ...param });
          this.$emit('next', 4);
        } catch (err) {
          this.loading = false;
          this.status = setError('Internal Error');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
}
.content {
  margin-bottom: 24px;
  padding: 24px;
}
.buttons {
  display: flex;
  &:not(:last-child) {
    margin-right: 12px;
  }
}
.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  cursor: pointer;

  .img-container {
    position: relative;
    width: 150px;
    height: 100px;
  }
}
.img-container {
  position: relative;
  width: 300px;
  height: 200px;
}
.picture-uploading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 200px;
  width: 300px;
}
.img-preview {
  cursor: pointer;
}
.checkbox-container {
  display: flex;
  align-content: center;
}
</style>
