import db from '@/store/firestore';
import { snakeToCamel } from '@/utils/formats';

const USERS_ASSETS = 'user_assets';
const ENTITIES = 'entities';
const PROJECTS = 'projects';
const PROJECTS_DETAILED = 'projects_detailed';

const userAssetsRef = db.collection(USERS_ASSETS);
const entitiesRef = db.collection(ENTITIES);
const projectsRef = db.collection(PROJECTS);
const projectsDetailedRef = db.collection(PROJECTS_DETAILED);

const actions = {
  /**
   * Fetch and subscribe to a user doc by _id
   */
  getActiveUserProjects: (store, id) => {
    const state = { state: 'activeUserProjectList' };
    store.commit('SET_LOADING', state);
    return userAssetsRef
      .where('user_id', '==', id)
      .where('status', '==', 'active')
      .onSnapshot(
        (querySnapshot) => {
          const payload = [];
          querySnapshot.forEach((doc) => {
            payload.push({
              _id: doc.id,
              ...snakeToCamel(doc.data()),
            });
          });
          store.commit('SET_DATA_REPLACE', { ...state, payload });
        },
        (err) => {
          store.commit('SET_ERROR', state);
          console.error('Error getting document: ', err);
        },
      );
  },
  /**
   * Fetch and subscribe to projects
   */
  getActiveProjects: (store) => {
    const state = { state: 'activeProjectList' };
    store.commit('SET_LOADING', state);
    return projectsRef.where('status', '==', 'active').onSnapshot(
      (querySnapshot) => {
        const payload = [];
        querySnapshot.forEach((doc) => {
          payload.push({
            _id: doc.id,
            ...snakeToCamel(doc.data()),
          });
        });
        store.commit('SET_DATA_REPLACE', { ...state, payload });
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Fetch and subscribe to projects
   */
  getNewProjects: (store) => {
    const state = { state: 'newProjectList' };
    store.commit('SET_LOADING', state);
    return projectsRef.where('status', '==', 'new').onSnapshot(
      (querySnapshot) => {
        const payload = [];
        querySnapshot.forEach((doc) => {
          payload.push({
            _id: doc.id,
            ...snakeToCamel(doc.data()),
          });
        });
        store.commit('SET_DATA_REPLACE', { ...state, payload });
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Fetch and subscribe to project by id
   * Includes the project's details and entity data
   */
  getProject: (store, id) => {
    const state = { state: 'project' };
    store.commit('SET_LOADING', state);
    return projectsRef.doc(id).onSnapshot(
      async (doc) => {
        const data = snakeToCamel(doc.data());
        try {
          const entityDoc = await entitiesRef.doc(data.entityId).get();
          const payload = {
            _id: doc.id,
            ...data,
            entity: snakeToCamel(entityDoc.data()),
          };
          store.commit('SET_DATA_REPLACE', { ...state, payload });
        } catch (err) {
          store.commit('SET_ERROR', state);
        }
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Fetch and subscribe to projects_detailed by id
   */
  getProjectDetails: (store, id) => {
    const state = { state: 'projectDetails' };
    store.commit('SET_LOADING', state);
    return projectsDetailedRef.where('project_id', '==', id).onSnapshot(
      (querySnapshot) => {
        const payload = [];
        querySnapshot.forEach((doc) => {
          payload.push({
            _id: doc.id,
            ...snakeToCamel(doc.data()),
          });
        });
        store.commit('SET_DATA_REPLACE', { ...state, payload: payload[0] });
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Fetch and subscribe to a user asset by id
   * Includes the project's details and entity data
   */
  getUserAsset: (store, param) => {
    const state = { state: 'userAsset' };
    store.commit('SET_LOADING', state);
    return userAssetsRef
      .where('user_id', '==', param.userId)
      .where('project._id', '==', param.projectId)
      .onSnapshot(
        async (querySnapshot) => {
          try {
            const payload = [];
            querySnapshot.forEach((doc) => {
              payload.push({
                _id: doc.id,
                ...snakeToCamel(doc.data()),
              });
            });
            const data = payload[0];
            const entityDoc = await entitiesRef.doc(data.project.entityId).get();
            data.project.entity = snakeToCamel(entityDoc.data());
            store.commit('SET_DATA_REPLACE', { ...state, payload: data });
          } catch (err) {
            store.commit('SET_ERROR', state);
          }
        },
        (err) => {
          store.commit('SET_ERROR', state);
          console.error('Error getting document: ', err);
        },
      );
  },
};

export default actions;
