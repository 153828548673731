<template>
  <div>
    <div v-if="loading" class="page-loader">
      <div class="text-title mb-5">{{ loadingText }}</div>
      <v-progress-circular :size="80" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="text-title">{{ title }}</div>
        <div class="text-body">{{ caption }}</div>
        <div class="userpass-login">
          <v-text-field
            v-model="input.email"
            :label="email.label"
            :rules="email.rules"
            validate-on-blur
            outlined
            required
          ></v-text-field>
          <v-text-field
            v-model="input.password"
            :label="password.label"
            :rules="password.rules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            validate-on-blur
            outlined
            required
          ></v-text-field>
          <v-text-field
            v-model="input.password2"
            :label="password2.label"
            :rules="password2.rules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            validate-on-blur
            outlined
            required
          ></v-text-field>
        </div>
        <div class="checkbox-container">
          <v-checkbox v-model="checkbox" :rules="terms.rules" />
          <div class="text-body">
            {{ terms.text }} <a :href="terms.link.value">{{ terms.link.text }}</a>
          </div>
        </div>
        <Status :color="status.color">{{ status.text }}</Status>
        <Button @click="submitFormHandler" @keyup.enter.native="submitFormHandler">Sign up</Button>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from '@/services/firebase';
import { setError } from '@/utils/errors';
import form from '@/utils/validation/form';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';

export default {
  name: 'Signup',
  components: {
    Status,
    Button,
  },
  created() {
    if (!this.userAuth) this.loading = false;
  },
  data() {
    return {
      valid: true,
      loading: true,
      loadingText: 'Creating your account...',
      title: 'Sign up',
      caption: 'Sign in to Lyght with social media or email',
      email: {
        label: 'Email',
        rules: [form.required(), form.email()],
      },
      password: {
        label: 'Password',
        rules: [form.required()],
      },
      password2: {
        label: 'Retype Password',
        rules: [form.required(), (v) => v === this.input.password || 'Password does not match'],
      },
      terms: {
        text: 'I have read, understood and accept to the',
        link: {
          text: 'terms and conditions of Lyght.',
          value: '',
        },
        rules: [(v) => !!v],
      },
      checkbox: false,
      input: {
        email: '',
        password: '',
        password2: '',
      },
      showPassword: false,
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    userAuth() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      createUser: 'createUser',
    }),

    // local methods
    validate() {
      this.valid = this.$refs.form.validate();
    },

    async submitFormHandler() {
      this.validate();
      if (this.valid) {
        try {
          this.loading = true;
          // create account and user doc
          const res = await this.createAccount();
          await this.createUserProfile(res.user.uid);

          // send email verfication if not verified
          if (!res.user.emailVerified) {
            await firebase.auth().currentUser.sendEmailVerification();
          }

          // login with the new account
          await this.login();
          this.$router.replace({ name: 'onboarding' });
        } catch (err) {
          this.loading = false;
          this.status = setError(err);
        }
      }
    },

    createAccount() {
      return firebase.auth().createUserWithEmailAndPassword(this.input.email, this.input.password);
    },

    login() {
      return firebase.auth().signInWithEmailAndPassword(this.input.email, this.input.password);
    },

    createUserProfile(_id) {
      const userProfile = {
        _id,
        active: true,
        email: this.input.email,
        phoneNumberVerified: false,
        profileStatus: 'not_approved',
        role: 'user',
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      return this.createUser(userProfile);
    },
  },
};
</script>

<style lang="scss" scoped>
.social-login {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .btn {
    margin-top: 16px;
  }
}

.checkbox-container {
  display: flex;
  align-content: center;
}

.userpass-login {
  margin-top: 32px;
}
</style>
