<template>
  <v-card>
    <v-carousel :height="imgHeight" show-arrows-on-hover hide-delimiters>
      <v-carousel-item v-for="item in data.displayImgs" v-bind:key="item.url" :src="item.url"></v-carousel-item>
    </v-carousel>
    <div class="pa-5">
      <FlexRow class="text-subheading mb-4">
        <template #label>{{ data.name }}</template>
        <template #value>{{ getCityDisplay(data.address) }}</template>
      </FlexRow>
      <FlexRow v-for="field in fields" v-bind:key="field.key">
        <template #label>{{ field.text }}</template>
        <template #value>{{ renderValue(data, field) }}</template>
      </FlexRow>
    </div>
  </v-card>
</template>

<script>
import get from 'lodash/get';
import { formatDisplayVal } from '@/utils/formats';
import { dateDiff, formatDuration } from '@/utils/date';
import { FlexRow } from '@/components/Grid';

export default {
  name: 'ProjectSummary',
  components: {
    FlexRow,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgHeight: 272,
      fields: [
        {
          key: 'establishedDate',
          text: 'Farm establishment date',
        },
        {
          key: 'estimatedIrr',
          text: 'Estimated IRR',
          config: {
            format: '0.00%',
          },
        },
        {
          key: 'capacity',
          text: 'Farm Capacity',
          config: {
            suffix: 'kW',
          },
        },
        {
          key: 'panelsTotal',
          text: 'Total No. of Panels',
        },
        {
          key: 'projectDuration',
          text: 'Project Duration',
          getValue: this.projectDuration,
        },
      ],
      projectDurationText: 'Project Duration',
    };
  },
  methods: {
    getCityDisplay(address) {
      return address.city;
    },
    renderValue(data, field) {
      const { key, getValue, config = {} } = field;
      const value = get(data, key);

      return getValue ? getValue(value) : formatDisplayVal(value, config);
    },
    projectDuration() {
      const start = this.data.fundingEndDate;
      const end = this.data.ppaContractEndDate;
      const duration = dateDiff(start, end, 'all');
      const result = formatDuration(duration);
      return `${result.value} ${result.unit}s`;
    },
  },
};
</script>
