import Vue from 'vue';
import Vuex from 'vuex';
import users from './users';
import projects from './projects';
import orders from './orders';
import weather from './weather';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    projects,
    orders,
    weather,
  },
  state: {},
  mutations: {},
  actions: {},
});
