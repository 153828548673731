import { render, staticRenderFns } from "./DocumentList.vue?vue&type=template&id=a526d7ee&scoped=true&"
import script from "./DocumentList.vue?vue&type=script&lang=js&"
export * from "./DocumentList.vue?vue&type=script&lang=js&"
import style0 from "./DocumentList.vue?vue&type=style&index=0&id=a526d7ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a526d7ee",
  null
  
)

export default component.exports