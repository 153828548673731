<template>
  <v-card class="pa-5">
    <div class="flex-container text-subheading black--text mb-4">
      <div>{{ calculatorText.title }}</div>
    </div>
    <FlexRow>
      <template #label>{{ calculatorText.input }}</template>
      <template #value>
        <v-text-field v-model="calculatorInput" type="number" solo dense hide-details flat reverse></v-text-field>
      </template>
    </FlexRow>
    <v-slider
      v-model="calculatorInput"
      :max="data.panelsTotal - data.panelsSold"
      :min="1"
      class="slider"
      track-color="grey"
    ></v-slider>
    <FlexRow v-for="field in fields" v-bind:key="field.key">
      <template #label>{{ field.text }}</template>
      <template #value>
        <div class="text-subheading primary--text">
          {{ renderValue(data, field) }}
        </div>
      </template>
    </FlexRow>
  </v-card>
</template>

<script>
import get from 'lodash/get';
import { formatDisplayVal } from '@/utils/formats';
import { FlexRow } from '@/components/Grid';

export default {
  name: 'RoiCalculator',
  components: {
    FlexRow,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      calculatorInput: Math.round((this.data.panelsTotal - this.data.panelsSold) * 0.1),
      calculatorText: {
        title: 'Indicative Calculator',
        input: 'No. Of Panels',
        costPerPanel: 'Cost Per Panel',
        capitalInvestment: 'Capital Investment',
        estimated20YROI: 'Estimated 20-year Returns',
        estimatedYieldRate: 'Estimated Yield Rate',
        estimatedCarbonReduction: 'Estimated Carbon Reduction',
      },
      fields: [
        {
          key: 'panelCost',
          text: 'Cost Per Panel',
          getValue: this.panelCost,
        },
        {
          key: 'capitalInvestment',
          text: 'Capital Investment',
          getValue: this.capitalInvestment,
        },
        {
          key: 'estimated20YROI',
          text: 'Estimated 20-year Returns',
          getValue: this.estimated20YROI,
        },
        {
          key: 'estimatedYieldRate',
          text: 'Estimated Yield Rate',
          getValue: this.estimatedYieldRate,
        },
        {
          key: 'estimatedCarbonReduction',
          text: 'Estimated Carbon Reduction',
          getValue: this.estimatedCarbonReduction,
        },
      ],
      // temp hard-coded value
      tenure: 16,
      accumulatedGross: 13650760,
      totalProjectCost: 8959650,
      carbonPerKg: 3284,
    };
  },
  computed: {
    moneyFormat() {
      return { format: '0,0', suffix: 'NTD' };
    },
    percentageFormat() {
      return { format: '0.00%' };
    },
    weightFormat() {
      return { format: '0,0', suffix: 'KG' };
    },
  },
  methods: {
    panelCost() {
      const val = this.data.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
    capitalInvestment() {
      const val = this.data.panelCost * this.calculatorInput;
      return formatDisplayVal(val, this.moneyFormat);
    },
    estimated20YROI() {
      const val = (this.accumulatedGross * this.calculatorInput) / this.data.panelsTotal;
      return formatDisplayVal(val, this.moneyFormat);
    },
    estimatedYieldRate() {
      const val = this.accumulatedGross / this.tenure / this.totalProjectCost;
      return formatDisplayVal(val, this.percentageFormat);
    },
    estimatedCarbonReduction() {
      const val = this.calculatorInput * this.carbonPerKg;
      return formatDisplayVal(val, this.weightFormat);
    },
    renderValue(data, field) {
      const { key, getValue, config = {} } = field;
      const value = get(data, key);

      return getValue ? getValue(value) : formatDisplayVal(value, config);
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider >>> .v-slider--horizontal .v-slider__track-container {
  height: 5px;
}
.slider >>> .v-slider__track-background {
  border-radius: 2.5px;
}
.slider >>> .v-slider__track-fill {
  border-radius: 2.5px;
}
</style>
