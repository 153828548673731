<template>
  <v-row>
    <v-col v-for="item in infoCards" v-bind:key="item.key" cols="6">
      <v-card height="145" class="pa-3">
        <v-icon color="primary">{{ item.icon }}</v-icon>
        <div class="info-card-container">
          <div class="text-body grey--text" style="text-align: right">{{ item.text }}</div>
          <div class="text-subheading">
            <div v-if="item.subKey" style="text-align: right">{{ getValue(item.subKey) }}</div>
            <div style="text-align: right">{{ getValue(item.key) }}</div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatDisplayVal } from '@/utils/formats';

export default {
  name: 'AssetRoiDetails',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      infoCards: [
        {
          key: 'energyGeneratedDay',
          icon: 'mdi-solar-power',
          text: 'Energy Generated Last 24-hour',
        },
        {
          key: 'energyGeneratedMonth',
          subKey: 'assetQuantity',
          icon: 'mdi-flash',
          text: 'Energy Generated Last Month',
        },
        {
          key: 'estimatedReturnDay',
          icon: 'mdi-calendar-today',
          text: 'Estimated Returns Last 24-hour',
        },
        {
          key: 'estimatedReturnMonth',
          icon: 'mdi-calendar-month',
          text: 'Actual Return Last Month',
        },
      ],
    };
  },
  computed: {
    projectDetails() {
      return this.data.project;
    },
    energyFormat() {
      return { format: '0,0', suffix: 'kW' };
    },
    moneyFormat() {
      return { format: '0,0', suffix: 'NTD' };
    },
    energyGeneratedDay() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.energyFormat);
    },
    energyGeneratedMonth() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.energyFormat);
    },
    estimatedReturnDay() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
    estimatedReturnMonth() {
      const val = this.data.quantity * this.projectDetails.panelCost;
      return formatDisplayVal(val, this.moneyFormat);
    },
  },
  methods: {
    formatDisplayVal,
    getValue(key) {
      return this[key];
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card-container {
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
