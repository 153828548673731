<template>
  <SocialButton v-bind="$attrs" @click="clickHandler" :loading="loading" type="facebook" />
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { mapActions } from 'vuex';
import { SocialButton } from '@/components/Button';

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export default {
  name: 'FacebookLoginButton',
  components: {
    SocialButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      createUser: 'createUser',
    }),

    // local methods
    createUserProfile(user) {
      const { uid: _id, email, phoneNumber } = user;
      const [firstName, lastName] = user.displayName.split(' ');
      const userProfile = {
        _id,
        active: true,
        firstName,
        lastName,
        email,
        phoneNumber,
        phoneNumberVerified: false,
        profileStatus: 'not_approved',
        role: 'user',
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      return this.createUser(userProfile);
    },
    async clickHandler() {
      this.loading = true;
      try {
        const res = await this.login();
        await this.createUserProfile(res.user);
        this.$router.replace({ name: 'onboarding' });
      } catch (err) {
        this.loading = false;
        this.$emit('error', err);
      }
    },
    login() {
      return firebase.auth().signInWithPopup(facebookProvider);
    },
  },
};
</script>
