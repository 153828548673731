<template>
  <div>
    <v-card class="content elevation-3">
      <div class="title-container mb-5">
        <v-icon class="mr-3" color="primary">{{ titleIcon }}</v-icon>
        <span class="text-subheading">{{ title }}</span>
      </div>
      <div class="text-body black--text">{{ caption }}</div>
      <div class="text-body black--text">
        <div>
          {{ resendEmailText }} <a @click="resendEmailHandler">{{ resendEmailTriggerText }}</a>
        </div>
      </div>
      <Status :color="status.color">{{ status.text }}</Status>
    </v-card>
    <div class="buttons">
      <Button @click="handleNext" :loading="loading" color="primary">{{ buttonNext }}</Button>
    </div>
  </div>
</template>

<script>
import firebase from '@/services/firebase';
import { setError } from '@/utils/errors';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';

export default {
  name: 'EmailVerification',
  components: {
    Status,
    Button,
  },
  created() {
    if (this.isVerified()) this.$emit('next', 2);

    this.checkForVerifiedInterval = setInterval(() => {
      firebase
        .auth()
        .currentUser.reload()
        .then(() => {
          if (this.isVerified()) {
            clearInterval(this.checkForVerifiedInterval);
            this.$emit('next', 2);
          }
        });
    }, 1000);
  },
  beforeDestroy() {
    if (this.checkForVerifiedInterval) clearInterval(this.checkForVerifiedInterval);
  },
  data() {
    return {
      titleIcon: 'mdi-email-lock',
      title: "Let's make sure your email is valid",
      caption: 'Please verify your email by clicking on the verification link sent to your email.',
      resendEmailText: 'Did not receive email?',
      resendEmailTriggerText: 'Send again here',
      buttonNext: 'Continue',
      checkForVerifiedInterval: null,
      loading: false,
      status: {
        text: '',
        color: '',
      },
    };
  },
  methods: {
    isVerified() {
      return firebase.auth().currentUser.emailVerified;
    },
    sendEmailVerification() {
      return firebase.auth().currentUser.sendEmailVerification();
    },
    resendEmailHandler() {
      this.loading = true;
      firebase
        .auth()
        .currentUser.reload()
        .then(() => {
          if (!this.isVerified()) {
            this.sendEmailVerification()
              .then(() => {
                this.loading = false;
                this.status = {
                  text: 'A new verification link has been sent to your email!',
                  color: 'info--text',
                };
              })
              .catch((err) => {
                this.loading = false;
                this.status = setError(err);
              });
          } else {
            this.$emit('next', 2);
          }
        });
    },
    handleNext() {
      this.loading = true;
      firebase
        .auth()
        .currentUser.reload()
        .then(() => {
          if (this.isVerified()) {
            this.$emit('next', 2);
          } else {
            this.loading = false;
            this.status = setError(
              'Email is not verified. Please check your email for verification link from Lyght or request a new link',
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
}
.content {
  margin-bottom: 24px;
  padding: 24px;
}
.buttons {
  &:not(:last-child) {
    margin-right: 12px;
  }
}
</style>
