<template>
  <PageContainer :title="title" :description="description" :isLoading="isLoading" :isError="isError">
    <UserProjectDetails :data="userAsset.data"></UserProjectDetails>
  </PageContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatAddress } from '@/utils/formats';
import { PageContainer } from '@/features/Layout';
import UserProjectDetails from '@/features/Projects/UserProjectDetails.vue';

export default {
  name: 'UserProjectView',
  components: {
    PageContainer,
    UserProjectDetails,
  },
  created() {
    this.getUserAsset({
      userId: this.userId.data,
      projectId: this.$route.params.id,
    });
    this.getProjectDetails(this.$route.params.id);
  },
  data() {
    return {
      renderers: ['userAsset'],
      lang: 'en',
    };
  },
  computed: {
    ...mapGetters('users', {
      userId: 'userId',
    }),
    ...mapGetters('projects', {
      userAsset: 'userAsset',
    }),
    isLoading() {
      return this.renderers.some((r) => this[r].loading);
    },
    isError() {
      return this.renderers.some((r) => this[r].error);
    },
    title() {
      return !this.isLoading && !this.isError ? this.userAsset.data.project.name : '';
    },
    description() {
      return !this.isLoading && !this.isError ? formatAddress(this.userAsset.data.project.address) : '';
    },
  },
  methods: {
    ...mapActions('projects', {
      getUserAsset: 'getUserAsset',
      getProjectDetails: 'getProjectDetails',
    }),
  },
};
</script>
