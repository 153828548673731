var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-container",style:(_vm.containerStyle)},[_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item._id,attrs:{"cols":"12","md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2,"max-width":_vm.maxWidth},on:{"click":function($event){return _vm.$emit('item-click', item)}}},[_c('v-img',{staticClass:"black",attrs:{"height":_vm.imgHeight,"src":item.displayImgs[0].url}},[_c('div',{staticClass:"text-in-img"},[_c('div',{staticClass:"text-subheading white--text"},[_vm._v(_vm._s(_vm.estimatedIrrText))]),_c('div',{staticClass:"text-emphasis white--text"},[_vm._v(" "+_vm._s(_vm.formatDisplayVal(item.estimatedIrr, { format: '0.00%' }))+" ")])])]),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"flex-container text-subheading black--text mb-4"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(_vm.getCityDisplay(item.address)))])]),_vm._l((_vm.keys),function(ref){
var key = ref.key;
var text = ref.text;
var format = ref.format;
var prefix = ref.prefix;
var suffix = ref.suffix;
return _c('div',{key:key,staticClass:"flex-container text-body black--text mb-1"},[_c('div',[_vm._v(_vm._s(text))]),_c('div',[_vm._v(_vm._s(_vm.formatDisplayVal(item[key], { format: format, prefix: prefix, suffix: suffix })))])])})],2)],1)]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }