<template>
  <v-snackbar :value="updateExists" :timeout="10000" color="background" light bottom right vertical>
    <div class="snackbar-container">An update is available!</div>
    <template v-slot:action="{ attrs }">
      <Button @click="refreshApp" v-bind="attrs" color="primary" class="btn-container">
        <v-icon class="mr-2">mdi-cached</v-icon>
        Update
      </Button>
    </template>
  </v-snackbar>
</template>

<script>
import update from '@/mixins/update';
import { Button } from '@/components/Button';

export default {
  name: 'UpdateSnackbar',
  components: {
    Button,
  },
  mixins: [update],
};
</script>

<style lang="scss" scoped>
.snackbar-container {
  margin: 12px 0 0 12px;
  font-weight: 600;
}
.btn-container {
  margin: 12px;
}
</style>
