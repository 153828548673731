const getters = {
  activeUserProjectList(state) {
    return state.activeUserProjectList;
  },
  activeProjectList(state) {
    return state.activeProjectList;
  },
  newProjectList(state) {
    return state.newProjectList;
  },
  userAsset(state) {
    const { userAsset, projectDetails } = state;
    return {
      error: userAsset.error || projectDetails.error,
      loading: userAsset.loading || projectDetails.loading,
      data: {
        ...userAsset.data,
        project: {
          ...(userAsset.data && userAsset.data.project),
          ...(projectDetails.data && projectDetails.data),
        },
      },
    };
  },
  projectDetails(state) {
    const { project, projectDetails } = state;
    return {
      error: project.error || projectDetails.error,
      loading: project.loading || projectDetails.loading,
      data: {
        ...project.data,
        ...(projectDetails.data && projectDetails.data),
      },
    };
  },
};

export default getters;
