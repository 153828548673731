<template>
  <v-card class="pa-5">
    <v-row>
      <v-col cols="12" md="6">
        <div class="text-title">{{ panelsAvailable }} {{ panelsText }}</div>
        <div class="text-body grey--text">{{ availableText }}</div>
        <div class="text-title">{{ remainingTime }} {{ daysText }}</div>
        <div class="text-body grey--text">{{ remainingText }}</div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="panels-progress-values mb-3">
          <div class="text-subheading">{{ panelsSoldText }}</div>
          <div class="text-subheading black--text ml-2 mr-2">/</div>
          <div class="text-subheading primary--text text--darken-2">{{ panelsReservedText }}</div>
          <div class="text-subheading black--text ml-2 mr-2">/</div>
          <div class="text-subheading background--text text--darken-2">{{ panelsTotalText }}</div>
        </div>
        <div class="panels-progress-values mb-3">
          <div class="text-body">{{ data.panelsSold }}</div>
          <div class="text-body black--text ml-2 mr-2">/</div>
          <div class="text-body primary--text text--darken-2">{{ data.panelsReserved }}</div>
          <div class="text-body black--text ml-2 mr-2">/</div>
          <div class="text-body background--text text--darken-2">{{ data.panelsTotal }}</div>
        </div>
        <div class="panels-progress-container background darken-2">
          <div class="primary-value primary" :style="primaryValueStyle"></div>
          <div class="secondary-value primary darken-2" :style="secondaryValueStyle"></div>
        </div>
        <div class="button-container mt-5">
          <Button @click="openDialog">{{ panelsAvailable === 0 ? joinWaitlist : participateText }}</Button>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="pa-7">
        <div class="flex-container mb-7">
          <div class="text-title">Order Request</div>
          <v-btn @click="dialog = false" :disabled="loading" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="input"
            type="number"
            :label="label"
            :rules="rules"
            :loading="loading"
            :disabled="disabled"
            lazy-validation
            outlined
            required
          ></v-text-field>
        </v-form>
        <Status :color="status.color">{{ status.text }}</Status>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button :loading="loading" :disabled="loading" @click="submitHandler">
            {{ panelsAvailable === 0 ? joinWaitlist : submitText }}
          </Button>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { setError } from '@/utils/errors';
import { dateDiff } from '@/utils/date';
import form from '@/utils/validation/form';
import Status from '@/components/Popup/Status.vue';
import { Button } from '@/components/Button';

export default {
  name: 'ParticipationForm',
  components: {
    Status,
    Button,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      panelsText: 'Panels',
      availableText: 'Available for purchase',
      daysText: 'Days',
      remainingText: 'Remaining',
      panelsSoldText: 'Panels Sold',
      panelsReservedText: 'Panels Reserved',
      panelsTotalText: 'Total Panels',
      participateText: 'Participate',
      submitText: 'Submit',
      joinWaitlist: 'Join Waitlist',
      dialog: false,
      valid: true,
      input: null,
      label: 'No. of Panels To Purchase',
      rules: [form.required(), form.number(), this.maxPanels],
      loading: false,
      disabled: false,
      status: {
        text: '',
        color: '',
      },
    };
  },
  computed: {
    // vuex computed
    ...mapGetters('users', {
      user: 'user',
    }),
    // local computed
    panelsAvailable() {
      return this.data.panelsTotal - this.data.panelsSold - this.data.panelsReserved;
    },
    remainingTime() {
      const start = this.data.fundingStartDate;
      const end = this.data.fundingEndDate;
      const duration = dateDiff(start, end, 'day');
      return `${duration}`;
    },
    primaryValueStyle() {
      const value = (this.data.panelsSold / this.data.panelsTotal) * 100;
      return {
        width: value > 4.5 ? `${value}%` : '4.5%',
      };
    },
    secondaryValueStyle() {
      const value = ((this.data.panelsSold + this.data.panelsReserved) / this.data.panelsTotal) * 100;
      return {
        width: value > 4.5 ? `${value}%` : '4.5%',
      };
    },
  },
  methods: {
    // vuex methods
    ...mapActions('orders', {
      createOrder: 'createOrder',
    }),
    // local methods
    openDialog() {
      this.dialog = true;
    },
    maxPanels(v) {
      return (
        (v && (Number(v) <= this.panelsAvailable || this.panelsAvailable === 0)) ||
        `Max of ${this.panelsAvailable} panels available`
      );
    },
    validate() {
      this.valid = this.$refs.form.validate();
    },
    async submitHandler() {
      this.validate();
      if (this.valid) {
        try {
          this.loading = true;
          const user = this.user.data;
          const project = this.data;

          await this.createOrder({
            item: 'Solar Panel',
            quantity: Number(this.input),
            user: {
              _id: user._id,
              name: `${user.firstName} ${user.lastName}`,
            },
            project: {
              _id: project.projectId,
              name: project.name,
              panelsTotal: project.panelsTotal,
            },
            status: this.panelsAvailable === 0 ? 'waitlist' : 'unfulfilled',
          });
          this.loading = false;
          this.dialog = false;
        } catch (err) {
          this.loading = false;
          this.status = setError(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
.panels-progress-values {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.panels-progress-container {
  position: relative;
  height: 16px;
  border-radius: 8px;
  .primary-value {
    position: absolute;
    z-index: 2;
    height: 16px;
    border-radius: 8px;
  }
  .secondary-value {
    position: absolute;
    z-index: 1;
    height: 16px;
    border-radius: 8px;
  }
}
</style>
