<template>
  <div>
    <v-card class="content elevation-3">
      <div class="title-container mb-5">
        <v-icon class="mr-3" color="primary">{{ titleIcon }}</v-icon>
        <span class="text-subheading">{{ title }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from '@/services/firebase';

export default {
  name: 'PendingApproval',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.user.data.profileStatus === 'approved') {
      this.$router.replace({ name: 'summary' });
    }
  },
  data() {
    return {
      title:
        "You have completed your profile! The review process will take 2-3 days. We will notify you via email when there's changes on the status of your application.",
      titleIcon: 'mdi-cloud-check',
    };
  },
  methods: {
    // vuex methods
    ...mapActions('users', {
      updateUser: 'updateUser',
    }),
    // local methods
    approve() {
      const id = firebase.auth().currentUser.uid;
      const params = {
        id,
        profileStatus: 'approved',
      };
      this.updateUser({ params });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
}
.content {
  margin-bottom: 24px;
  padding: 24px;
}
</style>
