<template>
  <v-card class="pa-5">
    <div class="flex-container text-subheading black--text">
      <div>{{ title }}</div>
      <v-btn small @click="expand = !expand" icon>
        <v-icon color="primary">{{ expand ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <v-card v-show="expand" class="transparent mt-3" flat title>
        <div class="text-body black--text mb-5">{{ data.description }}</div>
        <FlexRow v-for="field in generalFields" v-bind:key="field.key">
          <template #label>
            <div class="text-subheading black--text">{{ field.text }}</div>
          </template>
          <template #value>
            {{ renderValue(data, field) }}
          </template>
        </FlexRow>

        <div class="flex-container text-subheading black--text mb-1">{{ manufacturerText }}</div>
        <FlexRow v-for="field in manufacturerFields" v-bind:key="field.key" class="ml-3">
          <template #label>
            <div class="text-subheading black--text">{{ field.text }}</div>
          </template>
          <template #value>
            {{ renderValue(data, field) }}
          </template>
        </FlexRow>

        <div class="flex-container text-subheading black--text mb-1">{{ insuranceText }}</div>
        <v-card
          v-for="item in data.insurances"
          v-bind:key="`${item.date}${item.type}`"
          class="background pa-3 mb-2"
          flat
        >
          <FlexRow v-for="field in insuranceFields" v-bind:key="field.key">
            <template #label>
              <div class="text-subheading black--text">{{ field.text }}</div>
            </template>
            <template #value>
              {{ renderValue(item, field) }}
            </template>
          </FlexRow>
        </v-card>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import get from 'lodash/get';
import statusList from '@/constants/projects/statusList.json';
import electricityPurchaserList from '@/constants/projects/electricityPurchaserList.json';
import solarPanelClassificationList from '@/constants/projects/solarPanelClassificationList.json';
import solarPanelGradeList from '@/constants/projects/solarPanelGradeList.json';
import insuranceProviderList from '@/constants/projects/insuranceProviderList.json';
import insuranceTypeList from '@/constants/projects/insuranceTypeList.json';
import solarPanelManufacturerList from '@/constants/projects/solarPanelManufacturerList.json';
import converterManufacturerList from '@/constants/projects/converterManufacturerList.json';
import gptManufacturerList from '@/constants/projects/gptManufacturerList.json';
import vcbManufacturerList from '@/constants/projects/vcbManufacturerList.json';
import { getTranslationList } from '@/utils/locale';
import { formatDate } from '@/utils/date';
import { formatDisplayVal, formatShortAddress, formatAddress } from '@/utils/formats';
import { FlexRow } from '@/components/Grid';

export default {
  name: 'ProjectDetails',
  components: {
    FlexRow,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    defaultExpand: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.expand = this.defaultExpand;
  },
  data() {
    return {
      title: 'More Details',
      expand: true,
      generalFields: [
        {
          key: 'entity.name',
          text: 'SPV Company',
        },
        {
          key: 'entity.incorporatedDate',
          text: 'SPV Year of Incorporation',
          getValue: (val) => formatDate(val, 'YYYY'),
        },
        {
          key: 'entity.address',
          text: 'SPV Registered Address',
          getValue: (val) => formatAddress(val),
        },
        {
          key: 'electricityPurchaser',
          text: 'Electricity Purchaser',
          getValue: (val) => this.getSelectionText(val, electricityPurchaserList),
        },
        {
          key: 'ppaSerialNumber',
          text: 'PPA Serial Number',
        },
        {
          key: 'ppaContractStartDate',
          text: 'PPA Contract Signed Date',
        },
        {
          key: 'ppaContractEndDate',
          text: 'PPA Contract End Date',
        },
        {
          key: 'status',
          text: 'Current Status',
          getValue: (val) => this.getSelectionText(val, statusList),
        },
        {
          key: 'classification',
          text: 'Solar Project Classification',
          getValue: (val) => this.getSelectionText(val, solarPanelClassificationList),
        },
        {
          key: 'grade',
          text: "Solar Project Lyght's Grade",
          getValue: (val) => this.getSelectionText(val, solarPanelGradeList),
        },
      ],
      manufacturerText: 'Manufacturers and EPC Types',
      manufacturerFields: [
        {
          key: 'manufacturers.solarPanelManufacturer',
          text: 'Solar Panel',
          getValue: (val) => this.getSelectionText(val, solarPanelManufacturerList),
        },
        {
          key: 'manufacturers.converterManufacturer',
          text: 'Converter',
          getValue: (val) => this.getSelectionText(val, converterManufacturerList),
        },
        {
          key: 'manufacturers.gptManufacturer',
          text: 'GPT',
          getValue: (val) => this.getSelectionText(val, gptManufacturerList),
        },
        {
          key: 'manufacturers.vcbManufacturer',
          text: 'VCB',
          getValue: (val) => this.getSelectionText(val, vcbManufacturerList),
        },
      ],
      insuranceText: 'Insurances',
      insuranceFields: [
        {
          key: 'provider',
          text: 'Insurance Provider',
          getValue: (val) => this.getSelectionText(val, insuranceProviderList),
        },
        {
          key: 'type',
          text: 'Insurance Type',
          getValue: (val) => this.getSelectionText(val, insuranceTypeList),
        },
      ],
    };
  },
  computed: {
    address() {
      return formatShortAddress(this.projectDetails.data.address);
    },
  },
  methods: {
    formatDisplayVal,
    getSelectionText(val, list) {
      const item = getTranslationList(list).find((x) => x.value === val);
      return item ? item.text : 'Invalid';
    },
    renderValue(data, field) {
      const { key, getValue, config = {} } = field;
      const value = get(data, key);

      return getValue ? getValue(value) : formatDisplayVal(value, config);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
