var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"content elevation-3"},[_c('div',{staticClass:"title-container mb-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.titleIcon))]),_c('span',{staticClass:"text-subheading"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.fields),function(ref){
var type = ref.type;
var key = ref.key;
var cols = ref.cols;
var label = ref.label;
var items = ref.items;
var onChange = ref.onChange;
var rules = ref.rules;
return _c('v-col',{key:key,class:(_vm.isXs || cols < 10) && 'mr-2',attrs:{"cols":"12","md":cols}},[_c(type,{tag:"component",attrs:{"label":label,"rules":rules,"disabled":_vm.loading,"items":items,"lazy-validation":"","outlined":"","required":""},on:{"change":function($event){onChange && onChange($event)}},model:{value:(_vm.input[key]),callback:function ($$v) {_vm.$set(_vm.input, key, $$v)},expression:"input[key]"}})],1)}),1),_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"no-gutters":""}},_vm._l((_vm.pictureUploads),function(ref){
var key = ref.key;
var label = ref.label;
var placeholder = ref.placeholder;
return _c('v-col',{key:key,staticClass:"mr-2",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"title-container mb-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-image-area")]),_c('span',{staticClass:"text-subheading"},[_vm._v(_vm._s(label))])],1),_c('UploadPhoto',{attrs:{"imgKey":key,"label":label,"src":_vm.input[key],"placeholder":placeholder,"path":_vm.getImgPath(key),"bucket":"users"},on:{"uploaded":_vm.pictureUploadedHandler}})],1)}),1)],1)],1),_c('Status',{attrs:{"color":_vm.status.color}},[_vm._v(_vm._s(_vm.status.text))]),_c('div',{staticClass:"buttons"},[_c('Button',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.handleNext}},[_vm._v(_vm._s(_vm.buttonNext))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }