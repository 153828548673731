import db from '@/store/firestore';
import { setCreateParam, setUpdateParam } from '@/store/utils';
import { snakeToCamel } from '@/utils/formats';

const COLLECTION = 'orders';
const docRef = db.collection(COLLECTION);

const actions = {
  /**
   * Create an order doc if it does not exist
   */
  createOrder: async (store, param) => {
    const formattedParam = setCreateParam(param);
    try {
      await docRef.add(formattedParam);
      console.log('Created an order');
    } catch (err) {
      console.error('Error creating document: ', err);
    }
  },
  /**
   * Fetch and subscribe to an order doc by _id
   */
  getOrders: (store, param) => {
    const state = { state: 'orders' };
    store.commit('SET_LOADING', state);
    return docRef.doc(param).onSnapshot(
      (docSnapshot) => {
        if (docSnapshot.exists) {
          const payload = docSnapshot.data() || {};
          store.commit('SET_DATA_REPLACE', { ...state, payload: snakeToCamel(payload) });
        } else {
          store.commit('SET_ERROR', state);
          console.error('Unable to find document!');
        }
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Update an order doc by _id
   */
  updateOrder: async (store, param) => {
    try {
      const { id, ...updateParam } = param;
      const formattedParam = setUpdateParam(updateParam);
      await docRef.doc(id).update(formattedParam);
    } catch (err) {
      console.error(err);
    }
  },
};

export default actions;
