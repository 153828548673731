import Vue from 'vue';
import VueRouter from 'vue-router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import Login from '@/views/Login.vue';
import Onboarding from '@/views/Onboarding.vue';
import Summary from '@/views/Summary.vue';
import Profile from '@/views/Profile.vue';
import Projects from '@/views/Projects/Projects.vue';
import UserProjectView from '@/views/Projects/UserProjectView.vue';
import ActiveProjectView from '@/views/Projects/ActiveProjectView.vue';
import NewProjectView from '@/views/Projects/NewProjectView.vue';
import Messages from '@/views/Messages.vue';
import HelpCentre from '@/views/HelpCentre.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'login' },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Signup.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/summary',
    name: 'summary',
    component: Summary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects/invested/:id',
    name: 'projects/invested/:id',
    component: UserProjectView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects/active/:id',
    name: 'projects/active/:id',
    component: ActiveProjectView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects/new/:id',
    name: 'projects/new/:id',
    component: NewProjectView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: HelpCentre,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'others',
    component: Summary,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (!requiresAuth && isAuthenticated) {
    next('/onboarding');
  } else {
    next();
  }
});

export default router;
