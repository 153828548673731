const PASS_MIN_LENGTH = 6;

const required = ({ msg } = {}) => (v) => {
  const err = msg || 'This field is required';
  return !!v || err;
};

const number = ({ msg } = {}) => (v) => {
  const err = msg || 'Invalid input';
  return /^\d+$/.test(v) || err;
};

const email = ({ msg } = {}) => (v) => {
  const err = msg || 'Invalid e-mail';
  return /.+@.+\..+/.test(v) || err;
};

const exactLength = ({ msg, len = 9 } = {}) => (v) => {
  const err = msg || `Must be a length of ${len}`;
  return (v && v.length === len) || err;
};

const maxLength = ({ msg, len = 3 } = {}) => (v) => {
  const err = msg || `Must be less than ${len} characters`;
  return (v && v.length < len) || err;
};

const minLength = ({ msg, len = 3 } = {}) => (v) => {
  const err = msg || `Must be more than ${len} characters`;
  return (v && v.length > len) || err;
};

const password = ({ msg } = {}) => {
  const err = msg || `Password must be more than ${PASS_MIN_LENGTH} characters`;
  return minLength(err, PASS_MIN_LENGTH);
};

const retypePassword = ({ msg, pass } = {}) => (v) => {
  const err = msg || 'Password does not match';
  return v === pass || err;
};

module.exports = {
  required,
  number,
  email,
  exactLength,
  maxLength,
  minLength,
  password,
  retypePassword,
};
