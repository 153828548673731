import db from '@/store/firestore';
import { camelToSnake, snakeToCamel } from '@/utils/formats';

const COLLECTION = 'users';
const docRef = db.collection(COLLECTION);

const actions = {
  /**
   * Create a user doc if it does not exist
   */
  createUser: (store, param) => {
    const formattedParam = camelToSnake(param);
    return docRef
      .doc(formattedParam._id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          docRef
            .doc(formattedParam._id)
            .set(formattedParam)
            .then(() => {
              console.log('Created profile');
            })
            .catch((err) => {
              console.error('Error creating document: ', err);
            });
        }
      })
      .catch((err) => {
        console.error('Creating creating profile', err);
      });
  },
  /**
   * Fetch and subscribe to a user doc by _id
   */
  getUser: (store, param) => {
    const state = { state: 'user' };
    store.commit('SET_LOADING', state);
    return docRef.doc(param).onSnapshot(
      (docSnapshot) => {
        if (docSnapshot.exists) {
          const payload = docSnapshot.data() || {};
          store.commit('SET_DATA_REPLACE', { ...state, payload: snakeToCamel(payload) });
        } else {
          store.commit('SET_ERROR', state);
          console.error('Unable to find document!');
        }
      },
      (err) => {
        store.commit('SET_ERROR', state);
        console.error('Error getting document: ', err);
      },
    );
  },
  /**
   * Update a user doc by _id
   */
  updateUser: async (store, param) => {
    try {
      const { id, ...updateParam } = param;
      const formattedParam = camelToSnake(updateParam);
      await docRef.doc(id).update(formattedParam);
    } catch (err) {
      console.error(err);
    }
  },
};

export default actions;
